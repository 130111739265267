(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("window"));
	else if(typeof define === 'function' && define.amd)
		define(["window"], factory);
	else if(typeof exports === 'object')
		exports["signalviewVendors"] = factory(require("window"));
	else
		root["signalviewVendors"] = factory(root["window"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__161__) {
return 